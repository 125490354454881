import isEmpty from 'lodash/isEmpty'

export default {
  name: 'DiscountsOverview',
  data() {
    return {
      discountItems:
        typeof this.$t('DiscountsOverview.items') === 'object' && !isEmpty(this.$t('DiscountsOverview.items'))
          ? this.$t('DiscountsOverview.items')
          : [],
    }
  },
}
